<template>
  <div class="zhuanti container" v-if="canShow">
    <Tanchuang :path="path" :id="id" />
    <Kcz :path="path" :id="id" />
    <div class="topImg"><img :src="zhuanti.thumbnails[0].src" /></div>
    <div class="title">{{ zhuanti.title }}</div>
    <div class="categorys">
      <div :class="item.active ? 'active' : ''" class="category" v-for="(item, index) in zhuanti.categories" :key="index" @click="change(index, item.id)">
        {{ item.title }}
      </div>
    </div>
    <div class="lists">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :immediate-check="false">
        <div class="list" v-for="(item, index) in list" :key="index">
          <wx-open-launch-app v-if="isWeixin && is_version" :appid="appId" :extinfo="extinfo" class="open-launch-app" @error="openError">
            <script type="text/wxtag-template">
              <style>
                .btn{
                  width:100%;
                  height: 120px
                }
              </style>
              <div class="btn"></div>
            </script>
          </wx-open-launch-app>
          <div class="list-l">
            <div class="list-title">{{ item.title }}</div>
            <div class="list-time">{{ item.published_at_human }}</div>
          </div>
          <div class="list-r"><img v-if="item.thumbnails.length > 0" :src="item.thumbnails[0].src" /></div>
        </div>
      </van-list>
    </div>

    <OpenApp2 :path="path" :id="id" />
  </div>
</template>

<script>
  import Tanchuang from "@/components/tanchuang";
  import Kcz from "@/components/kcz";
  import OpenApp2 from "@/components/openApp2";
  import { isWeixin } from "@/utils/common";
  import { getSpecial, getSpecialArticle } from "@/utils/api";
  import { wxShare, is_version } from "@/utils/wxShare";

  export default {
    name: "zhuanti",
    components: { Tanchuang, Kcz, OpenApp2 },
    data() {
      return {
        appId: "wx720bb4d01c924127",
        isWeixin: isWeixin(),
        is_version: is_version(),
        canShow: false,
        id: "",
        path: "zhuanti",
        page: 1,
        category: "",
        zhuanti: {},
        list: [],
        loading: false,
        finished: false,
      };
    },
    computed: {
      extinfo() {
        return JSON.stringify({
          path: "/" + this.path,
          id: this.id,
        });
      },
    },
    methods: {
      async getSpecial(id) {
        const res = await getSpecial(id);
        this.zhuanti = res.data.data;
        for (let i = 0; i < this.zhuanti.categories; i++) {
          this.zhuanti.categories[i].active = false;
        }
        this.zhuanti.categories = [{ title: "全部", id: "", active: true }].concat(this.zhuanti.categories);
        document.title = this.zhuanti.title;
        this.onLoad();
        this.canShow = true;
        wxShare(this.zhuanti.share_config.title, this.zhuanti.share_config.share_img, this.zhuanti.share_config.share_link, this.zhuanti.share_config.summary);
      },
      async getSpecialArticle(id, category, page) {},
      change(index, id) {
        this.list = [];
        this.page = 1;
        this.finished = false;
        this.loading = true;
        this.zhuanti.categories.forEach(element => {
          element.active = false;
        });
        this.zhuanti.categories[index].active = true;
        this.category = id;
        this.onLoad();
      },
      openError() {
        this.$router.push({ name: "index" });
      },
      onLoad(category) {
        setTimeout(async () => {
          const res = await getSpecialArticle(this.id, this.category, this.page);
          this.page = this.page + 1;
          if (res.data.data.length == 0) {
            this.finished = true;
            return;
          }
          this.list = this.list.concat(res.data.data);
          this.loading = false;
        }, 200);
      },
    },
    created() {
      this.id = this.$route.query.id;
      if (this.id) {
      } else {
        this.$router.push({ name: "index" });
      }
      this.getSpecial(this.id);
    },
  };
</script>

<style lang="scss" scoped>
  .title {
    margin: 0 auto;
    width: 92%;
    font-size: 20px;
    line-height: 48px;
    color: #303030;
    border-bottom: 1px solid #eee;
  }
  .categorys {
    display: flex;
    margin: 10px auto;
    width: 92%;
    flex-wrap: wrap;
    .category {
      background: #ffffff;
      border: 1px solid #ebebeb;
      color: #5d5d5d;
      border-radius: 17px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 20px;
      padding: 6px 10px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .active {
      color: #db5c54;
      border-color: #db5c54;
    }
  }
  .lists {
    width: 92%;
    margin: 0 auto;
    padding-bottom: 50px;
    .list {
      border-bottom: 1px solid #eee;
      display: flex;
      padding: 15px 0;
      position: relative;
      .list-l {
        flex: 1;
        margin-right: 20px;
        .list-title {
          font-size: 18px;
          font-weight: 500;
          line-height: 1.5;
          color: #1f1f1f;
          width: 100%;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .list-time {
          margin-top: 8px;
          font-size: 10px;
          font-weight: 500;
          line-height: 16px;
          color: #8f8f8f;
        }
      }
      .list-r {
        flex: none;
        width: 30%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      &:nth-last-child(1) {
        border: none;
      }
    }
  }
</style>
